<template>
  <div
    class="px-5 pt-5 pb-10 flex flex-col justify-between h-full overflow-scroll">
    <div>
      <router-link :to="{ name: 'StepFive' }">
        <BackIcon class="mb-10" />
      </router-link>
      <h3 class=" text-center text-lg mb-10 px-5">When are you going to make
        feeling good a part of your routine?</h3>

      <div class="flex flex-col">
        <label class="inline-flex mt-3">
          <div><input v-model="when" type="radio" name="when"
              class="form-radio text-customOrange h-5 w-5"
              value="before_you_brush_your_teeth"></div><span
            class="ml-2 text-gray-700 text-sm">Before you brush your
            teeth</span>
        </label>

        <label class="inline-flex mt-3">
          <div><input v-model="when" type="radio" name="when"
              class="form-radio text-customOrange h-5 w-5"
              value="before_your_cup_of_coffee"></div><span
            class="ml-2 text-gray-700 text-sm">Before your cup of coffee</span>
        </label>

        <label class="inline-flex mt-3">
          <div><input v-model="when" type="radio" name="when"
              class="form-radio text-customOrange h-5 w-5"
              value="on_a_lunch_break"></div><span
            class="ml-2 text-gray-700 text-sm">On a lunch break</span>
        </label>

        <label class="inline-flex mt-3 ">
          <div><input v-model="when" type="radio" name="when"
              class="form-radio text-customOrange h-5 w-5" value="at_the_gym">
          </div><span class="ml-2 text-gray-700 text-sm">At the gym</span>
        </label>

        <label class="inline-flex mt-3">
          <div><input v-model="when" type="radio" name="when"
              class="form-radio text-customOrange h-5 w-5"
              value="clear_brain_fog"></div><span
            class="ml-2 text-gray-700 text-sm">Clear brain fog</span>

        </label>

        <label class="inline-flex mt-3">
          <div><input v-model="when" type="radio" name="when"
              class="form-radio text-customOrange h-5 w-5"
              value="on_commercial_break/before_the_netflix_show_loads"></div>
          <span class="ml-2 text-gray-700 text-sm">On commercial break/Before
            the
            Netflix show loads </span>
        </label>
        <label class="inline-flex mt-3">
          <div><input v-model="when" type="radio" name="when"
              class="form-radio text-customOrange h-5 w-5"
              value="before_you_go_to_bed"></div><span
            class="ml-2 text-gray-700 text-sm">Before you go to bed</span>
        </label>
      </div>
    </div>
    <div>
      <div class="flex space-x-1 items-center justify-center mb-8">
        <div v-for="item in items" :key="item.id"
          class=" rounded-full h-2 w-2 bg-customPurple"></div>
      </div>
      <div class="w-full flex justify-center">
        <div class="w-10/12">
          <MainButton text="Finish" class="h-14" @click="onFinish" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import BackIcon from "@/components/svg/BackIcon";
import MainButton from "@/components/shared/MainButton";

import { CHECK_USER } from "@/store/types/actions";

export default {
  name: "StepSix",

  components: {
    BackIcon,
    MainButton,
  },

  data: () => ({
    when: "before_you_brush_your_teeth",
    items: ["Item1", "Item2", "Item3", "Item4", "Item5", "Item6"],
  }),

  mounted: function () {
    this.setWhen(this.when);
  },

  methods: {
    ...mapMutations({
      setWhen: "onboarding/SET_WHEN",
    }),

    ...mapActions({
      completeOnboarding: "onboarding/complete",
      checkUser: `auth/${CHECK_USER}`,
    }),

    onFinish: async function () {
      try {
        this.loading = true;

        await this.completeOnboarding();
        await this.checkUser();

        this.$router.push({ name: "home" });
      } catch (ex) {
        console.log(ex);
      } finally {
        this.loading = false;
      }
    },
  },

  watch: {
    when: function (value) {
      this.setWhen(value);
    },
  },
};
</script>
<style >
</style>